import React from "react";
import {Link} from "react-router-dom";
// Components
import DivBtn from "../../../UI/DivBtn";
// Style
import {ArtistBox, ArtistDetailsBox, ArtistImage, ArtistWrap, ArtistButtonWrapper} from "../style";
import {Paragraph, MediumHeading, OptionsLink} from "../../../../Style";
// Assets
import {ArtistData} from "../../../../Data/artistData";

const ArtistContent = () => {

    return (
        <ArtistWrap>
            {ArtistData.map((el) => (
                <ArtistBox>
                    <ArtistImage src={el.ima}/>
                    <ArtistDetailsBox>
                        <MediumHeading>
                            {el.heading}
                        </MediumHeading>
                        <Paragraph>
                            {el.paragraph_one}
                        </Paragraph>
                        <Paragraph>
                            {el.paragraph_two}
                        </Paragraph>
                        <ArtistButtonWrapper>
                            <DivBtn>
                                <Link to={el.btn_link}>
                                    <OptionsLink>
                                        {el.btn_text}
                                    </OptionsLink>
                                </Link>
                            </DivBtn>
                            <DivBtn>
                                <Link to={'/booking'}>
                                    <OptionsLink>
                                        Booking
                                    </OptionsLink>
                                </Link>
                            </DivBtn>
                        </ArtistButtonWrapper>
                    </ArtistDetailsBox>
                </ArtistBox>
            ))}
        </ArtistWrap>
    )
}

export default ArtistContent;
