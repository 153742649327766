import styled from 'styled-components'
import {QUERIES} from "../../../Style";
import {JoinCont, JoinBox} from "../Join/JoinContent/style";

export const PrivacyCont = styled(JoinCont)``;
export const PrivacyBox = styled(JoinBox)`
  @media (${QUERIES.medium}) {
        width: 70%;
    }
`;

