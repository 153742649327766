import React from 'react';
import {AftercareBox, AftercareCont} from "./style";
import {Heading, MediumHeading, Paragraph} from "../../../Style";

const AftercareContent = () => {
    return (
        <AftercareCont>
            <AftercareBox>
                <Heading>Tattoo aftercare</Heading>
                <MediumHeading style={{textAlign: 'center'}}>Recommended process for dealing with a fresh tattoo</MediumHeading>
                <MediumHeading>First night</MediumHeading>
                <ul>
                    <li>
                        A -> (If the tattoo is wrapped in cling film)
                            <ul>
                                <li>
                                    <Paragraph>
                                        A.1 -> The foil should be removed after reaching home or after a maximum of 4 hours.
                                    </Paragraph>
                                </li>
                                <li>
                                    <Paragraph>
                                        A.2 -> After removing the foil, the tattoo should be washed with water or water with a good quality soap that does not cause irritation (neutral soap/intimate wash)and dried with paper towels.  Leave the tattoo dry until you go to bed.
                                    </Paragraph>
                                </li>
                                <li>
                                    <Paragraph>
                                        If from the moment of removing the dressing until going to bed, we are forced to stay outside the house - in conditions that endanger the cleanliness of a fresh tattoo, apply a thin layer of cream on the tattoo and wrap it in cling film. Repeat the process if 3 hours have elapsed
                                    </Paragraph>
                                </li>
                                <li>
                                    <Paragraph>
                                       A.3 -> Immediately before the first night, spread a thin layer of ointment on the tattoo and wrap it in cling film.
                                    </Paragraph>
                                </li>
                            </ul>
                    </li>
                    <li>
                       B -> (If the tattoo is secured with a protective tattoo film-second skin-dressing)
                        <ul>
                            <li>
                                <Paragraph>
                                   B.1 -> The dressing can be safely left on the skin for 24 hours.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                   B.2 -> If a lot of fluid has accumulated under the dressing, the dressing should be removed or cleanly punctured and excess fluids drained.  (If the dressing comes off before the first night, refer to A.2)
                                </Paragraph>
                            </li>
                        </ul>
                    </li>
                </ul>
                <MediumHeading>Care:</MediumHeading>
                <ol>
                    <li>
                        <Paragraph>Lubricate the tattoo with specialized ointments in a thin layer for about 2 weeks.</Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            Only use ointments designed to heal tattoos.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            Lubricate about 3-4 times a day.  During the first days, rinse the tattoo and dry it before lubricating it.  (It is very important to keep the tattoo clean, the body will expel various fluids, ink and will be susceptible to infections and infections.)
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            Wash with water or water with a good quality non-irritating soap and dry with paper towels.  Repeat the washing and lubrication process for the next 2 weeks.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            If the tattoo is exposed to unfavorable conditions during the first 2 days, the tattoo may be covered with foil.  However, it should be remembered that the tattoo under the foil takes longer to heal and may burn.
                        </Paragraph>
                    </li>
                    <Paragraph>The tattoo should not be kept under the same foil for more than 3-4 hours.</Paragraph>
                </ol>
                <MediumHeading> What else should be remembered:</MediumHeading>
                <ul>
                    <li>
                        <Paragraph>
                            The ointment should be massaged into the skin, do not leave excess cream on the skin.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            During healing, the epidermis will peel off, do not scratch the skin, this can cause defects in the tattoo!
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            The skin after tattooing may be swollen and red for up to several days.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            Limit alcohol, the tattoo will heal poorly as alcohol slows down the healing process.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            Avoid physical trainings for at least a week, recommended 2 weeks.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            After 2 weeks, we can stop taking ointments and switch to regular moisturizing lotions
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            We avoid long baths for 3 weeks and sun exposure for a month.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            Do not strain or stretch the skin at the place of tattooing as this can lead to a displacement of the pigment in the skin.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            After the tattoo has healed, use tattoo filters when they are exposed to direct sunlight.  (Filter preferably SPF 50 + ) Not using filters leads to significant color fading.
                        </Paragraph>
                    </li>
                    If you are satisfied with the tattoo, we will be grateful for sharing your opinion on
                    <a href="https://www.google.com/maps/place/Aura+Reykjavik/@64.1458704,-21.8969606,15z/data=!4m7!3m6!1s0x0:0x6185b5f2e1897693!8m2!3d64.1458606!4d-21.8969571!9m1!1b1" target='_blank' rel="noopener noreferrer" >
                        <span style={{color: 'orange'}}> Google</span>
                    </a> and Recommendation on
                    <a href="https://www.facebook.com/anikawr/" target="_blank" rel="noopener noreferrer" >
                        <span style={{color: 'orange'}}> Facebook </span>
                    </a>page.
                </ul>
            </AftercareBox>
        </AftercareCont>
    );
};

export default AftercareContent;