import AnnaImg from '../Assets/Profile/Anna/AnnaImg.jpeg'
import PolaImg from '../Assets/Profile/Pola/PolaImg.jpeg'
import MalkaImg from '../Assets/Profile/Malka/MalkaImg.jpg'
import MonikaImg from '../Assets/Profile/Monika/MonikaImg.jpg'

export const ArtistData = [
    {
        "ima": `${AnnaImg}`,
        "heading": "ethereal.tattoo",
        "btn_text": "View gallery",
        'btn_link': '/anna',
        'paragraph_one': 'Visual artist and tattooist. Tattooing since 2016. Mainly focused on nature, emotions and constant flow. I take inspiration from observing the world surrounding me. Working with extreme fine lines mixed with different techniques. ',
    },
    {
        "ima": `${PolaImg}`,
        "heading": "Eerie.ttt",
        "btn_text": "View gallery",
        'btn_link': '/eerie',
        'paragraph_one': 'Visual artist and tattooist, that finds her soul wandering through Mother Nature, transiency of existence ancient wisdom and the infinity of the Universe. In my works I use both machine and handpoke method.',
    },
    {
        "ima": `${MalkaImg}`,
        "heading": "malka.ink",
        "btn_text": "View gallery",
        'btn_link': '/malka',
        'paragraph_one': 'Visual artist based in Reykjavik, a graduate of the Polish National Filmschool, cinematographer and photographer working worldwide. Tattooing with a hand poke technique.',
        "paragraph_two": `More: www.ovors.com / @ovors`,
    },
    {
        "ima": `${MonikaImg}`,
        "heading": "monika_malewska",
        "btn_text": "View gallery",
        'btn_link': '/monika',
        'paragraph_one': 'Wandering soul with my feet firmly on the ground and head lost in the clouds. My creative work is mainly focused on all that is mystic and surreal: astral worship, dreams, spirituality and beliefs. ',
    }
]
