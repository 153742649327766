// Ann
import pic1 from '../Assets/Profile/Anna/Tattoo/1.jpeg'
import pic2 from '../Assets/Profile/Anna/Tattoo/2.jpeg'
import pic3 from '../Assets/Profile/Anna/Tattoo/3.jpeg'
import pic4 from '../Assets/Profile/Anna/Tattoo/4.jpeg'
import pic5 from '../Assets/Profile/Anna/Tattoo/5.jpeg'
import pic6 from '../Assets/Profile/Anna/Tattoo/6.jpeg'
import pic7 from '../Assets/Profile/Anna/Tattoo/7.jpeg'
import pic8 from '../Assets/Profile/Anna/Tattoo/8.jpeg'
import pic9 from '../Assets/Profile/Anna/Tattoo/9.jpeg'
import pic10 from '../Assets/Profile/Anna/Tattoo/10.jpeg'
import pic11 from '../Assets/Profile/Anna/Tattoo/11.jpeg'
import pic12 from '../Assets/Profile/Anna/Tattoo/12.jpeg'

// POLA
import pic1p from '../Assets/Profile/Pola/Tattoo/1.jpeg'
import pic2p from '../Assets/Profile/Pola/Tattoo/2.jpeg'
import pic3p from '../Assets/Profile/Pola/Tattoo/3.jpeg'
import pic4p from '../Assets/Profile/Pola/Tattoo/4.jpeg'
import pic5p from '../Assets/Profile/Pola/Tattoo/5.jpeg'
import pic6p from '../Assets/Profile/Pola/Tattoo/6.jpeg'
import pic7p from '../Assets/Profile/Pola/Tattoo/7.jpeg'
import pic8p from '../Assets/Profile/Pola/Tattoo/8.jpeg'
import pic9p from '../Assets/Profile/Pola/Tattoo/9.jpeg'
import pic10p from '../Assets/Profile/Pola/Tattoo/10.jpeg'
import pic11p from '../Assets/Profile/Pola/Tattoo/11.jpeg'
import pic12p from '../Assets/Profile/Pola/Tattoo/12.jpeg'

// MALKA pic1mal
import pic1mal from '../Assets/Profile/Malka/Tattoo/1.jpeg'
import pic2mal from '../Assets/Profile/Malka/Tattoo/2.jpeg'
import pic3mal from '../Assets/Profile/Malka/Tattoo/3.jpeg'
import pic4mal from '../Assets/Profile/Malka/Tattoo/4.jpeg'
import pic5mal from '../Assets/Profile/Malka/Tattoo/5.jpg'
import pic6mal from '../Assets/Profile/Malka/Tattoo/6.jpg'
import pic7mal from '../Assets/Profile/Malka/Tattoo/7.jpeg'
import pic8mal from '../Assets/Profile/Malka/Tattoo/8.jpeg'
import pic9mal from '../Assets/Profile/Malka/Tattoo/9.jpg'
import pic10mal from '../Assets/Profile/Malka/Tattoo/10.jpeg'
import pic11mal from '../Assets/Profile/Malka/Tattoo/11.jpeg'
import pic12mal from '../Assets/Profile/Malka/Tattoo/12.jpeg'

// Mon
import pic1mon from '../Assets/Profile/Monika/Tattoo/1.jpg'
import pic2mon from '../Assets/Profile/Monika/Tattoo/2.jpg'
import pic3mon from '../Assets/Profile/Monika/Tattoo/3.jpg'
import pic4mon from '../Assets/Profile/Monika/Tattoo/4.jpg'
import pic5mon from '../Assets/Profile/Monika/Tattoo/5.jpg'
import pic6mon from '../Assets/Profile/Monika/Tattoo/6.jpg'
import pic7mon from '../Assets/Profile/Monika/Tattoo/7.jpg'
import pic8mon from '../Assets/Profile/Monika/Tattoo/8.jpg'
import pic9mon from '../Assets/Profile/Monika/Tattoo/9.jpg'
import pic10mon from '../Assets/Profile/Monika/Tattoo/10.jpg'
import pic11mon from '../Assets/Profile/Monika/Tattoo/11.jpg'
import pic12mon from '../Assets/Profile/Monika/Tattoo/12.jpg'

// GUESTS
// MARTA
import pic1gm from '../Assets/Profile/Guests/Marta/Tattoo/1.JPG'
import pic2gm from '../Assets/Profile/Guests/Marta/Tattoo/2.jpeg'
import pic3gm from '../Assets/Profile/Guests/Marta/Tattoo/3.jpeg'
import pic4gm from '../Assets/Profile/Guests/Marta/Tattoo/4.jpeg'
import pic5gm from '../Assets/Profile/Guests/Marta/Tattoo/5.jpeg'

// ROMAN
import pic1gr from '../Assets/Profile/Guests/Roman/Tattoo/1.jpeg'
import pic2gr from '../Assets/Profile/Guests/Roman/Tattoo/2.jpeg'
import pic3gr from '../Assets/Profile/Guests/Roman/Tattoo/3.jpeg'
import pic4gr from '../Assets/Profile/Guests/Roman/Tattoo/4.jpeg'
import pic5gr from '../Assets/Profile/Guests/Roman/Tattoo/5.jpeg'

// OAK
import pic1go from '../Assets/Profile/Guests/Oakbranch/Tattoo/1.jpeg'
import pic2go from '../Assets/Profile/Guests/Oakbranch/Tattoo/2.jpeg'
import pic3go from '../Assets/Profile/Guests/Oakbranch/Tattoo/3.jpeg'
import pic4go from '../Assets/Profile/Guests/Oakbranch/Tattoo/4.jpeg'
import pic5go from '../Assets/Profile/Guests/Oakbranch/Tattoo/5.jpeg'

// PROSTOLNIJNA
import pic1gp from '../Assets/Profile/Guests/Prostolinijna/Tattoo/1.jpg'
import pic2gp from '../Assets/Profile/Guests/Prostolinijna/Tattoo/2.jpg'
import pic3gp from '../Assets/Profile/Guests/Prostolinijna/Tattoo/3.jpg'
import pic4gp from '../Assets/Profile/Guests/Prostolinijna/Tattoo/4.jpg'
import pic5gp from '../Assets/Profile/Guests/Prostolinijna/Tattoo/5.jpg'

// SIANKO
import pic1gs from '../Assets/Profile/Guests/Sianko/Tattoo/1.jpg'
import pic2gs from '../Assets/Profile/Guests/Sianko/Tattoo/2.jpg'
import pic3gs from '../Assets/Profile/Guests/Sianko/Tattoo/3.jpg'
import pic4gs from '../Assets/Profile/Guests/Sianko/Tattoo/4.jpg'
import pic5gs from '../Assets/Profile/Guests/Sianko/Tattoo/5.jpg'

// SZTUKA
import pic1gsz from '../Assets/Profile/Guests/SztukaWojny/Tattoo/1.JPG'
import pic2gsz from '../Assets/Profile/Guests/SztukaWojny/Tattoo/2.JPG'
import pic3gsz from '../Assets/Profile/Guests/SztukaWojny/Tattoo/3.JPG'
import pic4gsz from '../Assets/Profile/Guests/SztukaWojny/Tattoo/4.JPG'
import pic5gsz from '../Assets/Profile/Guests/SztukaWojny/Tattoo/5.JPG'

export const tattooAnna = [
    {
        src: `${pic1}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic6}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic7}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic8}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic9}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic10}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic11}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic12}`,
        width: 1,
        height: 1
    }
]

export const tottooPola = [
    {
        src: `${pic1p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic6p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic7p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic8p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic9p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic10p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic11p}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic12p}`,
        width: 1,
        height: 1
    }
    ]

export const tattooMalka = [
    {
        src: `${pic1mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic6mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic7mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic8mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic9mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic10mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic11mal}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic12mal}`,
        width: 1,
        height: 1
    }
    ]

export const tattooMonika = [
    {
        src: `${pic1mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic6mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic7mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic8mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic9mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic10mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic11mon}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic12mon}`,
        width: 1,
        height: 1
    }
]

export const tattooMarta = [
    {
        src: `${pic1gm}`,
        width: .5,
        height: .5,
    },
    {
        src: `${pic2gm}`,
        width: .5,
        height: .5,
    },
    {
        src: `${pic3gm}`,
        width: .5,
        height: .5,
    },
    {
        src: `${pic4gm}`,
        width: .5,
        height: .5,
    },
    {
        src: `${pic5gm}`,
        width: .5,
        height: .5,
    },
]

export const tattooRoman = [
    {
        src: `${pic1gr}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2gr}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3gr}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4gr}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5gr}`,
        width: 1,
        height: 1
    },
]

export const tattooOak = [
    {
        src: `${pic1go}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2go}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3go}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4go}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5go}`,
        width: 1,
        height: 1
    },
]

export const tattooProstolinijna = [
    {
        src: `${pic1gp}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2gp}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3gp}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4gp}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5gp}`,
        width: 1,
        height: 1
    },
]

export const tattooSianko = [
    {
        src: `${pic1gs}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2gs}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3gs}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4gs}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5gs}`,
        width: 1,
        height: 1
    },
]

export const tattooSztuka = [
    {
        src: `${pic1gsz}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic2gsz}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic3gsz}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic4gsz}`,
        width: 1,
        height: 1
    },
    {
        src: `${pic5gsz}`,
        width: 1,
        height: 1
    },
]
