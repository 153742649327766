import React from 'react';
import Navbar from "../../Components/Contents/NavBar";
import {Container} from "../../Style";
import PrivacyAndPolicy from "../../Components/PagesContents/PrivacyAndPolicy";

const Privacy = () => {
    return (
        <Container>
            <Navbar/>
            <PrivacyAndPolicy/>
        </Container>
    );
};

export default Privacy;
