import styled from "styled-components";
import {ParallaxBox, QUERIES} from "../../../Style";

export const DivideWrapper = styled(ParallaxBox)`
    flex-direction: column;
    //background-position: right;
    width: 100%;
    height: 30vh;
    background-color: #0d0d0d;
`;

export const BtnWrapperDesktop = styled.div`
    display: none;
  
    @media (${QUERIES.medium}) {
        display: block;
    }
`;