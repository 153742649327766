import React from "react";
import {Link} from "react-router-dom";
import Navbar from "../../../../Contents/NavBar";
import FooterContent from "../../../../Contents/FooterContent";
// Components
import ArtistHead from "../../../../Contents/DivideComponent";
import ImgSlide from "../../../../Contents/ImgSlide";
import DivBtn from "../../../../UI/DivBtn";
// Style
import {ArtBtnWrap, Separator, ArtistContentBox, InnerWithSibling, VideoReelsEl} from "../style";
import {BottomBox, Inner, OptionsLink, Paragraph} from "../../../../../Style";
// Data
import annaVideo from '../../../../../Assets/Profile/Anna/annaMovie.mp4'
import annaVideoPoster from '../../../../../Assets/Profile/Anna/AnnaImg.jpeg'
import {useViewport} from "../../../../../hooks/useViewport";

const AnnaPage = () => {
    const {width} = useViewport();

    return (
        <div>
            <Navbar/>
            <ArtistHead text={'ethereal.tattoo'}/>
            <ArtistContentBox>
                <InnerWithSibling>
                    {width >= 1092 &&
                        <VideoReelsEl loop="loop" autoPlay="autoPlay" muted poster={annaVideoPoster}>
                            <source src={annaVideo} type="video/mp4"/>
                            Your browser does not support video tag
                        </VideoReelsEl>
                    }
                    <Paragraph>My name is Anna. I have been playing with art for as long as I can remember. In the past,
                        I drew and painted with many different techniques, participating in various projects and art
                        competitions.</Paragraph>
                    <Paragraph>My paths with the tattoo industry crossed in 2012 when I got my first tattoo, and it
                        awaken my curiosity. For several years, I tested myself a bit in this field, and in 2016 I
                        finally decided of picking this road for good. Since then, I have been working with a tattoo
                        continuously and since 2018 it is the only form of work I do. Welcome to my fineline, ethereal
                        world. </Paragraph>
                    <ArtBtnWrap>
                        <DivBtn>
                            <Link to='/booking'>
                                <OptionsLink>
                                    Make an appointment
                                </OptionsLink>
                            </Link>
                        </DivBtn>
                        <Separator/>
                        <DivBtn>
                            <a href='https://www.instagram.com/ethereal.tattoo/' target="_blank"
                               rel="noopener noreferrer">
                                <OptionsLink>
                                    Check my IG
                                </OptionsLink>
                            </a>
                        </DivBtn>
                    </ArtBtnWrap>
                </InnerWithSibling>
                <Inner>
                    <ImgSlide person="anna"/>
                </Inner>
            </ArtistContentBox>
            <BottomBox>
                <FooterContent/>
            </BottomBox>
        </div>
    )
}

export default AnnaPage;
