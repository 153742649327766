import React from 'react';
import {StudioPolicyBox, StudioPolicyCont} from "./style";
import {Heading, MediumHeading, Paragraph} from "../../../Style";

const StudioPolicyContent = () => {
    return (
        <StudioPolicyCont>
            <StudioPolicyBox>
                <Heading>Studio policy</Heading>
                <MediumHeading style={{textAlign: 'center'}}>GETTING A TATTOO IS ON YOUR OWN RESPONSIBILITY.</MediumHeading>
                <ul>
                    <li>
                        <Paragraph>The artist and the Studio are not responsible for complications resulting from the procedure and poor handling of a fresh tattoo.</Paragraph>
                    </li>
                    <li>
                        <Paragraph>Getting a tattoo breaks the epidermis, which is associated with the risk of contracting infectious diseases such as viral hepatitis and HIV. The skin is exposed to infections within a few days of the treatment.</Paragraph>
                    </li>
                    <li>
                        <Paragraph>The studio and the artist are not responsible for the side effects associated with the tattoo treatment.</Paragraph>
                    </li>
                    <li>
                        <Paragraph>The course of the tattooing procedure:</Paragraph>
                        <ul>
                            <li>
                                <Paragraph>The artist disinfects the skin with an antiseptic spray/liquid.  If there is hair on the skin, it is removed with a disposable shaver and the skin is disinfected again.  The artist, wearing disposable gloves, applies a special preparation (gel) to the skin that allows to reflect the pattern (tracing) on ​​the skin.  After accepting the size of the pattern on the body, the customer waits 10 to 20 minutes for the pattern to dry.  The artist prepares disposable materials for the procedure.  Disposable are needles, machine handles, cups for ink, ink, and disposable towels.  Only the machine with the power supply are reusable, the whole thing is disinfected before and after the procedure and during the service it is packed in disposable foil sleeves.  The entire procedure takes place in disposable gloves that are changed after a specified time.  After the tattoo is finished, the skin is secured with a vapor-permeable dressing called "second skin" or cling film.</Paragraph>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Paragraph>Materials used up during tattooing, such as needles, machine handles, ink cups with ink, are disposed of by a specialized company that collects materials contaminated with microorganisms.</Paragraph>
                    </li>
                </ul>
                <MediumHeading style={{textAlign: 'center'}}>A TATTOO IS AN ARTISTIC CRAFTSMANSHIP</MediumHeading>
                <ul>
                    <li>
                        <Paragraph>The tattoo is a made-to-order item, the fee is non-refundable.</Paragraph>
                    </li>
                    <li>
                        <Paragraph>In case of a significant tattoo loss:</Paragraph>
                        <ul>
                            <li>
                                <Paragraph>you are entitled to get a free correction of a given fragmentation after the tattoo is fully healed.</Paragraph>
                            </li>
                            <li>
                                <Paragraph>The improvement is due no later than 90 days from the date of its implementation.  (If the tattoo needs improvement, we recommend that you improve it with another tattoo, it facilitates the healing process and does not limit our lives quality for an additional 2-3 weeks, as if it were done separately.)</Paragraph>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Paragraph>The artist may stop and finish the procedure.</Paragraph>
                        <ul>
                            <li>
                                <Paragraph>The treatment may be discontinued if the client's well-being or skin changes occur that may affect the tattoo.  Interrupted treatment is payable.  Due to the need to arrange another visit, the amount of ISK 5000 is added due to the need to use new, sterile materials and tools.</Paragraph>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Paragraph>The client undertakes to follow the healing process</Paragraph>
                        <ul>
                            <li>
                                <Paragraph> A recommended healing process can be obtained from your artist.</Paragraph>
                            </li>
                        </ul>
                    </li>
                </ul>
            </StudioPolicyBox>
        </StudioPolicyCont>
    );
};

export default StudioPolicyContent;