import React from 'react';
import Navbar from "../../../../Contents/NavBar";
import ArtistHead from "../../../../Contents/DivideComponent";
import {ArtBtnWrap, ArtistContentBox, InnerWithSibling, Separator, VideoReelsEl} from "../style";
import DivBtn from "../../../../UI/DivBtn";
import {Link} from "react-router-dom";
import {BottomBox, Inner, OptionsLink, Paragraph} from "../../../../../Style";
import ImgSlide from "../../../../Contents/ImgSlide";
import FooterContent from "../../../../Contents/FooterContent";
import malkaVideo from '../../../../../Assets/Profile/Malka/malkaMovie.mp4'
import malkaVideoPoster from '../../../../../Assets/Profile/Malka/MalkaImg.jpg'
import {useViewport} from "../../../../../hooks/useViewport";

const MalkaPage = () => {
    const {width} = useViewport();

    return (
        <div>
            <Navbar/>
            <ArtistHead text={'malka.ink'}/>
            <ArtistContentBox>
                <InnerWithSibling>
                    {width >= 1092 &&
                        <VideoReelsEl loop="loop" autoPlay="autoPlay" muted poster={malkaVideoPoster}>
                            <source src={malkaVideo} type="video/mp4"/>
                            Your browser does not support video tag
                        </VideoReelsEl>
                    }
                    <Paragraph>Visual artist based in Reykjavik, a graduate of the Polish National Filmschool,
                        cinematographer and photographer working worldwide. Tattooing with a hand poke
                        technique.</Paragraph>
                    <ArtBtnWrap>
                        <DivBtn>
                            <Link to='/booking'>
                                <OptionsLink>
                                    Make an appointment
                                </OptionsLink>
                            </Link>
                        </DivBtn>
                        <Separator/>
                        <DivBtn>
                            <a href='https://www.instagram.com/malka.ink' target="_blank" rel="noopener noreferrer">
                                <OptionsLink>
                                    Check my IG
                                </OptionsLink>
                            </a>
                        </DivBtn>
                    </ArtBtnWrap>
                </InnerWithSibling>
                <Inner>
                    <ImgSlide person="malka"/>
                </Inner>
            </ArtistContentBox>
            <BottomBox>
                <FooterContent/>
            </BottomBox>
        </div>
    )
};

export default MalkaPage;