import React from 'react';
import {Container} from "../../Style";
import Navbar from "../../Components/Contents/NavBar";
import StudioPolicyContent from "../../Components/PagesContents/StudioPolicy";

const StudioPolicy = () => {
    return (
        <Container>
            <Navbar/>
            <StudioPolicyContent/>
        </Container>
    );
};

export default StudioPolicy;