import { useEffect } from 'react';
import {useLocation, withRouter} from 'react-router';
import ReactGA from "react-ga";

const PageViewsAnal = ({ children }) => {
    let location = useLocation()
    useEffect(() => {
        if(!window.GA_INITIALIZED) {
            ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
            window.GA_INITIALIZED = true;
        }
        ReactGA.set({page: location.pathname})
        ReactGA.pageview(location.pathname)

    }, [location]);

    return children;
};

export default withRouter(PageViewsAnal);
