import styled from "styled-components";
import {QUERIES} from "../../../Style";

export const ArtistWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;

   @media (${QUERIES.small}) {
        padding: 0 8%;
        flex-direction: row;
   }
`;

export const ArtistBox = styled.div`
    padding: 17px 0;
    flex-direction: column;

 @media (${QUERIES.small}) {
     h3 {
        font-size: 1.8vw;
        margin-bottom: 0;
     }

     display: flex;
     flex-direction: row;
   }
`;

export const ArtistDetailsBox = styled.div``;

export const ArtistImage = styled.img`
    align-self: center; 
    width: 100%;
    border-radius: 6px;
    filter: grayscale(60%);
 
    @media (${QUERIES.small}) {
        width: 150px;
        margin-right: 30px;
        align-self: flex-start;
    }
 
    @media (${QUERIES.medium}) {
        width: 300px;
    }
`;

export const ArtistButtonWrapper = styled.div`
    display: flex;
    text-align: center;
    gap: 15px;
`;
