import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
// Components
import DivBtn from "../../UI/DivBtn";
// Style
import {DivideWrapper, BtnWrapperDesktop} from "./style";
import {Paragraph, Heading, OptionsLink, SMButtonWrapper} from "../../../Style";
// Assets
import backPicAnna from '../../../Assets/Profile/Anna/AnnaBg1.jpg'
import backPicPol from '../../../Assets/Profile/Pola/PolaBg2.jpg'
import backPicMalka from '../../../Assets/Profile/Malka/MalkaBg1.png'
import backPicFaq from '../../../Assets/Backgrounds/b2c.jpg'
import backPicMonika from '../../../Assets/Backgrounds/b0.jpg'

const DivideComponent = props => {
    const {text, paragraph} = props;
    const [isProfile, setIsProfile] = useState(false);

    let bgPosition = null;
    let bgImg = null;
    let guestHeight = null;
    let mobileBtn = null

    const makeMobileBtn = (textInner, link, showOnDesktop) => {
        mobileBtn = (
            <SMButtonWrapper style={showOnDesktop && {display: 'block'}}>
                <DivBtn>
                    <Link to={link}>
                        <OptionsLink>
                            {textInner}
                        </OptionsLink>
                    </Link>
                </DivBtn>
            </SMButtonWrapper>
        )
    }

    if (text === "FAQ") {
        makeMobileBtn('FAQ', '/faq')
    }
    if (text === "OUR GUESTS") {
        makeMobileBtn('Check Artists', '/guests', true)
        guestHeight = "30vh"
    }

    switch (text) {
        case "ethereal.tattoo":
            bgImg = backPicAnna
            break;
        case 'Eerie.ttt':
            bgImg = backPicPol
            break;
        case 'malka.ink':
            bgImg = backPicMalka
            break;
        case 'monika_malewska':
            bgImg = backPicMonika
            break;
        case "FAQ":
            bgImg = backPicFaq
            bgPosition = 'right'
            break;
        default:
            bgImg = null;
    }

    useEffect(() => {
        if (text === "ethereal.tattoo" || text === 'Eerie.ttt' || text === 'malka.ink') setIsProfile(true);
    }, [text])

    const bookingButton = (
        <BtnWrapperDesktop>
            <DivBtn>
                <Link to={'/booking'}>
                    <OptionsLink>
                        MAKE AN APPOINTMENT
                    </OptionsLink>
                </Link>
            </DivBtn><
        /BtnWrapperDesktop>
    )

    return (
        <DivideWrapper style={isProfile ? {
            background: `url(${bgImg}) no-repeat left top`,
            backgroundSize: 'cover'
        } : {backgroundImage: `url(${bgImg})`, backgroundPosition: `${bgPosition}`, height: `${guestHeight}`}}>
            <Heading>{text}</Heading>
            <Paragraph style={{textAlign: "center", padding: "0 20px"}}>{paragraph}</Paragraph>
            {isProfile && bookingButton}
            {mobileBtn}
        </DivideWrapper>
    )
}

export default DivideComponent;
