import styled from "styled-components";
import {QUERIES} from "../../../../Style";

export const LoaderBooking = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.6);
`

export const Logo = styled.img`
    height: 70px;
    
    @media (${QUERIES.medium}) {
        height: 120px;
    }
`;
