import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const ErrorPopup = () => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        window.location.reload();
    };


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">{"❗️ERROR❗"}</DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description" >
                        <span style={{color: "black"}}>It seems that we are not able to proceed with your booking since the form was not <span style={{fontWeight: "bold", color: "red", textDecoration: "underline"}}>filled out compliantly!</span></span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ErrorPopup;