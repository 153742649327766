import React from "react";
import {Link} from "react-router-dom";
// Components
import BookingForm from '../BookingForm'
import DivBtn from "../../../UI/DivBtn";
// Style
import {Inner, OptionsLink, Heading, BtnWrapper} from "../../../../Style";
import {TextBox, TextBoxWhite} from "./style";

const BookingPage = () => {
    return (
        <Inner>
            <Heading> MAKE AN APPOINTMENT </Heading>
            <TextBox>
                Please read our FAQ before making a booking. You can find there all information about how to prepare for an appointment, how much it cost, and when we are sending custom designs.
            </TextBox>
            <TextBoxWhite>
                To send an inquiry, please carefully fill out ALL fields of the form. Otherwise it will not be delivered.
                If subbmiting is successful, the information about receiving the request will be displayed on the device screen.  Thank you.
            </TextBoxWhite>
            <BtnWrapper>
                <DivBtn>
                    <Link to='/faq'>
                        <OptionsLink>
                            FAQ
                        </OptionsLink>
                    </Link>
                </DivBtn>
            </BtnWrapper>
            <BookingForm/>
        </Inner>
    )
}

export default BookingPage;