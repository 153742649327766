import React from 'react';
import Navbar from "../../../../Contents/NavBar";
import ArtistHead from "../../../../Contents/DivideComponent";
import {ArtBtnWrap, ArtistContentBox, InnerWithSibling, Separator, VideoReelsEl} from "../style";
import monikaVideoPoster from "../../../../../Assets/Profile/Monika/MonikaImg.jpg";
import {BottomBox, Inner, OptionsLink, Paragraph} from "../../../../../Style";
import DivBtn from "../../../../UI/DivBtn";
import {Link} from "react-router-dom";
import ImgSlide from "../../../../Contents/ImgSlide";
import FooterContent from "../../../../Contents/FooterContent";
import {useViewport} from "../../../../../hooks/useViewport";

const MonikaPage = () => {
    const {width} = useViewport();

    return (
        <div>
            <Navbar/>
            <ArtistHead text={'monika_malewska'}/>
            <ArtistContentBox>
                <InnerWithSibling>
                    {width >= 1092 &&
                        <VideoReelsEl loop="loop" autoPlay="autoPlay" muted poster={monikaVideoPoster}>
                            {/*<source src={polaVideo} type="video/mp4"/>*/}
                            Your browser does not support video tag
                        </VideoReelsEl>
                    }
                    <Paragraph>Wandering soul with my feet firmly on the ground and head lost in the clouds. My creative
                        work is mainly focused on all that is mystic and surreal: astral worship, dreams, spirituality
                        and beliefs.
                        <br/>
                        I’m also fascinated by the most mysterious worlds, the ones existing inside every human.
                        <br/>
                        When designing tattoos, I try to tell stories. They are based on your/my experiences, dreams,
                        visions or reflect your fascinations, desires, values.</Paragraph>
                    <ArtBtnWrap>
                        <DivBtn>
                            <Link to='/booking'>
                                <OptionsLink>
                                    Make an appointment
                                </OptionsLink>
                            </Link>
                        </DivBtn>
                        <Separator/>
                        <DivBtn>
                            <a href='https://www.instagram.com/monika_malewska' target="_blank" rel="noopener noreferrer">
                                <OptionsLink>
                                    Check my IG
                                </OptionsLink>
                            </a>
                        </DivBtn>
                    </ArtBtnWrap>
                </InnerWithSibling>
                <Inner>
                    <ImgSlide person="monika"/>
                </Inner>
            </ArtistContentBox>
            <BottomBox>
                <FooterContent/>
            </BottomBox>
        </div>
    );
};

export default MonikaPage;