import React from 'react';
//Style
import {LoaderBooking, Logo} from "./style";
//Assets
import LogoImg from '../../../../Assets/Icons/logo.png'


const BookingLoader = () => {
    return (
        <LoaderBooking>
            <div className="loaderWrapper">
                <Logo src={LogoImg} style={{marginBottom: '170px'}}/>
                <div className="loader">
                    <div className="inner one"></div>
                    <div className="inner two"></div>
                    <div className="inner three"></div>
                </div>
            </div>
        </LoaderBooking>
    );
};

export default BookingLoader;
