// TODO add links to <a>
export const FooterData = [
    {
        "heading": "Location",
        'paragraph_one': 'Skipholt 15',
        'paragraph_two': 'Reykjavik 105',
    },
    {
        "heading": "Hours",
        'paragraph_one': 'Appointment base studio',
        'paragraph_two': 'Call or message us :)',
        'btn_link': '/booking',
        'btn_text': 'Link to booking',
        'btn_link_two': '/covid-19',
        'btn_text_two': 'COVID-19'
    },
    {
        "heading": "Contact",
        'paragraph_one': 'aura.reykjavik@gmail.com',
        'paragraph_two': '',
        'btn_link': '/join',
        'btn_text': 'Join Team',
        'btn_link_two': '/privacy',
        'btn_text_two': 'PRIVACY AND POLICY'
    },
]
