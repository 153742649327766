import styled from "styled-components";
import {QUERIES, BtnWrapper, Inner} from "../../../../Style";

export const ArtBtnWrap = styled(BtnWrapper)`
    margin-bottom: -20px;
    display: flex;
    flex-direction: column;
    
    @media(${QUERIES.medium}) {
        margin-bottom: -70px;
        width: 300px;
    }
`;

export const Separator = styled.div`
    margin: 0 20px;
`

export const ArtistContentBox = styled.div`
    @media(${QUERIES.medium}) {
        display: flex;
    }
`;

export const InnerWithSibling = styled(Inner)`
  padding: 50px 13%;
  
  @media (${QUERIES.medium}) {
    align-items: center;
    width: 900px; // todo adjust
    padding: 100px 0 100px 8%;
  }
`

export const VideoReelsEl = styled.video`
  display: none;

  @media (${QUERIES.medium}) {
    display: block;
    width: 385px; // todo adjust
    //object-fit: cover;
    padding-bottom: 20px;
    filter: grayscale(70%);
    border-radius: 6px;
  }
`;