import React from 'react';
import Navbar from "../../Components/Contents/NavBar";
import {Container} from "../../Style";
import AftercareContent from "../../Components/PagesContents/Aftercare";

const Aftercare = () => {
    return (
        <Container>
            <Navbar/>
            <AftercareContent/>
        </Container>
    );
};

export default Aftercare;