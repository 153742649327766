import styled from "styled-components";
import {JoinBox} from "../Join/JoinContent/style";
import {ParallaxBox, QUERIES} from "../../../Style";
import bg from '../../../Assets/Backgrounds/b7.jpeg'

export const AftercareCont = styled(ParallaxBox)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw;
  min-height: 100vh;
  padding: 100px 0 50px 0;
  background-image: url(${bg});

  @media (${QUERIES.medium}) {
    padding: 100px 0;
    flex-direction: row;  
  }
`;

export const AftercareBox = styled(JoinBox)`
  @media (${QUERIES.medium}) {
        width: 70%;
    }
`;